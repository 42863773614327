import { Component, OnInit } from '@angular/core';
import { BeforeSlideDetail } from 'lightgallery/lg-events';
import lgVideo from 'lightgallery/plugins/video';
import { environment } from 'src/environments/environment';
import { services } from './../../services/services';
import { OwlOptions } from 'ngx-owl-carousel-o';


@Component({
  selector: 'app-home-page-eight',
  templateUrl: './home-page-eight.component.html',
  styleUrls: ['./home-page-eight.component.scss']
})
export class HomePageEightComponent implements OnInit {
  list: any[] = [];
  photoUrl: string = environment.serverUrl
  submenus: any;
  orphanMenus: any
  clergy: any[] = [];
  hajj: any;
  umrah: any;
  victim: any;
  alms: any;
  bhy:any;

  news: any[] = []

  constructor(private services: services) { }

  ngOnInit(): void {
    this.services.getFrontAllPublication().subscribe((data: any) => {
      this.list = data;

    });
    this.getAllFrontMenus();
    this.getAllFrontPage();
    this.getAllNews();
  }

  getAllFrontMenus() {
    this.services.getAllFrontMenuRecord().subscribe((data: any) => {
      this.submenus = data.filter(x => x.parentId !== null && x.isActive == true && x.url);
      this.orphanMenus = data.filter(menu => menu.parentId !== null && menu.isActive);
      this.clergy = data.filter(item => item.id == 20);
    })
  }

  getAllNews() {
    this.services.getFrontAllAnnouncements().subscribe((data: any) => {
      this.news = data.filter(news => news.isNews == true && news.isDeleted == false && news.isActive == true).sort((a, b) => {
        return a.order - b.order;
      });
      // console.log('haberler',this.news);
    });
  }

  getAllFrontPage() {
    this.services.getAllFrontPages().subscribe((data: any) => {
      // console.log('data: ', data);

      this.victim = data[4].frontPageRecords[0].code;
      this.hajj = data[5].frontPageRecords[0].code;
      this.umrah = data[6].frontPageRecords[0].code;
      this.alms = data[7].frontPageRecords[0].code;
      this.bhy = data[13].frontPageRecords[0].code;
    })
  }

  isIconOpen = false;

  toggleIcons() {
    this.isIconOpen = !this.isIconOpen;
  }

  currentTab = 'tab1';
  switchTab(event: MouseEvent, tab: string) {
    event.preventDefault();
    this.currentTab = tab;
  }

  //TAB DUYURULAR

  settings = {
    counter: false,
    plugins: [lgVideo]
  };
  onBeforeSlide = (detail: BeforeSlideDetail): void => {
    const { index, prevIndex } = detail;
  };


  selectedItem = null;

  toggleAccordionItem(list) {
    list.open = !list.open;
    if (this.selectedItem && this.selectedItem !== list) {
      this.selectedItem.open = false;
    }
    this.selectedItem = list;
  }

  //?HABERLER SLIDER
  blogSlides: OwlOptions = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: false,
    autoplay: true,
    margin: 30,
    navText: [
      "<i class='bx bx-left-arrow-alt'></i>",
      "<i class='bx bx-right-arrow-alt'></i>"
    ],
    autoplayTimeout: 3000,
    responsive: {
      0: {
        items: 1
      },
      768: {
        items: 2
      },
      1200: {
        items: 3
      }
    }
  }
}
