<section>
        <div *ngIf="isLoading" class="loader-wrapper">
                <div class="loader"></div>
                <div class="loader-section section-left"></div>
                <div class="loader-section section-right"></div>
        </div>
        <header class="header-area header-color-white">
                <!-- <div class="navbar-gap"> </div> -->
                <header class="header-one sticky-header" [ngClass]="[windowScrolled ? 'sticky-on':'']">
                        <div class="header-navigation" [ngClass]="[visible ? '' : 'breakpoint-on']"
                                (window:resize)="detectHeader()">
                                <div
                                        class="container-fluid d-flex align-items-center justify-content-center container-1470 ">
                                        <div class="header-left">
                                                <a routerLink="/">

                                                        <img src="assets/img/Diyanet_logo.svg" alt="logo" class="logo">
                                                        <img src="assets/img/advPhotos/advLogo.png" alt="logo"
                                                                class="logo">
                                                </a>
                                        </div>
                                        <div class="header-right d-flex align-items-center justify-content-end">
                                                <div class="site-nav-menu" [ngClass]="navMethod ? 'menu-opened':''">
                                                        <ul class="primary-menu" style="display: inline-block !important; margin-bottom: 1rem !important;
                                                        margin-top:1rem !important;" *ngFor="let item of orphanMenus">
                                                                <li>
                                                                        <a
                                                                                *ngIf="item?.frontMenuRecords[0]?.slug === '/'">
                                                                                {{item?.frontMenuRecords[0]?.name }}</a>
                                                                        <a href="javascript:void(0)"
                                                                                *ngIf="item?.frontMenuRecords[0]?.slug != '/' && item?.frontMenuRecords[0]?.slug != '/home'"
                                                                                [routerLink]="item?.frontMenuRecords[0]?.slug">{{
                                                                                item?.frontMenuRecords[0]?.name }}</a>

                                                                        <a href="javascript:void(0)"
                                                                                *ngIf="item?.frontMenuRecords[0]?.slug === '/home'"
                                                                                [routerLink]="'/'">{{
                                                                                item?.frontMenuRecords[0]?.name }}</a>

                                                                        <span class="dd-trigger"
                                                                                *ngIf="hasSubmenus(item)"
                                                                                (click)="trigger(item)">
                                                                                <i class="fal"
                                                                                        [ngClass]="item.open ? 'fa-solid fa-caret-up' : 'fa-solid fa-caret-down'"></i>


                                                                        </span>

                                                                        <ul class="submenu" *ngIf="hasSubmenus(item)"
                                                                                [ngClass]="item.open ? 'd-block' : ''">
                                                                                <li
                                                                                        *ngFor="let submenu of getSubmenus(item)">
                                                                                        <ng-container
                                                                                                *ngIf="submenu?.url">
                                                                                                <a [href]="submenu?.url"
                                                                                                        target="_blank">
                                                                                                        {{
                                                                                                        submenu.frontMenuRecords[0]?.name
                                                                                                        }}
                                                                                                </a>
                                                                                        </ng-container>
                                                                                        <ng-container
                                                                                                *ngIf="!submenu?.url && submenu.frontPageId==null">
                                                                                                <a
                                                                                                        [href]="submenu.frontMenuRecords[0]?.slug + '/' + submenu.code">
                                                                                                        {{
                                                                                                        submenu.frontMenuRecords[0]?.name
                                                                                                        }}
                                                                                                </a>
                                                                                        </ng-container>
                                                                                        <ng-container
                                                                                                *ngIf="!submenu?.url && submenu.frontPageId!=null">
                                                                                                <a
                                                                                                        [href]="'pages'+submenu.frontMenuRecords[0]?.slug + '/' + submenu.code">
                                                                                                        {{
                                                                                                        submenu.frontMenuRecords[0]?.name
                                                                                                        }}
                                                                                                </a>
                                                                                        </ng-container>
                                                                                </li>
                                                                        </ul>
                                                                </li>
                                                                <!-- Data -->
                                                        </ul>
                                                        <li style="list-style: none !important; margin-left:20px;"
                                                                class="btn-donate">
                                                                <button (click)="showDialog()" type="submit"
                                                                        class="btn-bagis">{{'DONATE'|translate}}</button>
                                                        </li>
                                                        <!-- <a href="javascript:void(0)" class="nav-close" (click)="toggleNav()">
                                                                <i class="fal fa-times"></i>
                                                        </a> -->
                                                </div>
                                                <div class="header-extra d-flex align-items-center">
                                                        <div class="offcanvas-widget d-lg-block d-none">

                                                        </div>
                                                        <div class="nav-toggler"
                                                                [ngClass]="navMethod ? 'menu-opened':''"
                                                                (click)="toggleNav()">
                                                                <span></span><span></span><span></span>
                                                        </div>
                                                </div>
                                        </div>
                                        <button (click)="showDialog()" type="submit" class="btn-donate2 btn-bagis"
                                                style="padding: 6px 20px !important;">{{'DONATE'|translate}}</button>
                                        <div _ngcontent-ng-c2405407033="" class="language-selector">
                                                <img _ngcontent-ng-c2405407033="" *ngFor="let item of languages"
                                                        class="country-flag ng-star-inserted"
                                                        [src]="photoUrl+item?.flag" alt="{{item.name}}"
                                                        (click)="setNewLanguageRefresh(item?.langCode)">
                                        </div>
                                </div>
                        </div>
                </header>


                <div class="top-header top-header-style-four ">
                        <div class="row align-items-center container prayer-container">
                                <div class="col-lg-4 col-md-4 prayer-time"
                                        style="display: flex; flex-direction: row; justify-content: center; align-items: baseline; gap:3px;">
                                        <ul class="top-header-contact-info">
                                                <li><a style="white-space: nowrap ;">{{'PRAYER_TIME'|translate}}</a>
                                                </li>
                                        </ul>
                                        <a>

                                                <div class="topbar-ordering">
                                                        <select class="form-select prayer-city"
                                                                (change)="onCountrySelected($event)">
                                                                <option *ngFor="let item of country"
                                                                        [value]="item.langcode">
                                                                        {{ item.name }}</option>
                                                        </select>

                                                </div>

                                        </a>
                                        <a>

                                                <div class="topbar-ordering">
                                                        <select class="form-select prayer-city"
                                                                (change)="onCitySelected($event)"
                                                                [(ngModel)]="selectedCity">
                                                                <option *ngFor="let item of cities" [value]="item.id">
                                                                        {{
                                                                        item.name }}</option>
                                                        </select>

                                                </div>

                                        </a>
                                </div>
                                <div class=" time-table col-lg-2 col-md-2">
                                        <div class="item col-lg-2 col-md-2">
                                                <div class="hour">
                                                        <ul>
                                                                <li class="time">{{'FAJR'|translate}}</li>
                                                                <li *ngIf="prayerTime" class="time_text">
                                                                        {{prayerTime?.fajr}}
                                                                </li>
                                                                <li *ngIf="!prayerTime" class="time_text">-</li>
                                                        </ul>
                                                </div>
                                        </div>
                                        <div class="item col-lg-2 col-md-2">
                                                <div class="hour">
                                                        <ul>
                                                                <li class="time">{{'SUNRISE'|translate}}</li>
                                                                <li *ngIf="prayerTime" class="time_text">
                                                                        {{prayerTime?.sunrise}}
                                                                </li>
                                                                <li *ngIf="!prayerTime" class="time_text">-</li>
                                                        </ul>
                                                </div>
                                        </div>
                                        <div class="item col-lg-2 col-md-2">
                                                <div class="hour">
                                                        <ul>
                                                                <li class="time">{{'DHUHR'|translate}}</li>
                                                                <li *ngIf="prayerTime" class="time_text">
                                                                        {{prayerTime?.dhuhr}}
                                                                </li>
                                                                <li *ngIf="!prayerTime" class="time_text">-</li>
                                                        </ul>
                                                </div>
                                        </div>
                                        <div class="item col-lg-2 col-md-2">
                                                <div class="hour">
                                                        <ul>
                                                                <li class="time">{{'ASR'|translate}}</li>
                                                                <li *ngIf="prayerTime" class="time_text">
                                                                        {{prayerTime?.asr}}
                                                                </li>
                                                                <li *ngIf="!prayerTime" class="time_text">-</li>
                                                        </ul>
                                                </div>
                                        </div>
                                        <div class="item col-lg-2 col-md-2">
                                                <div class="hour">
                                                        <ul>
                                                                <li class="time">{{'MAGHRIB'|translate}}</li>
                                                                <li *ngIf="prayerTime" class="time_text">
                                                                        {{prayerTime?.maghrib}}
                                                                </li>
                                                                <li *ngIf="!prayerTime" class="time_text">-</li>
                                                        </ul>
                                                </div>
                                        </div>
                                        <div class="item col-lg-2 col-md-2">
                                                <div class="hour">
                                                        <ul>
                                                                <li class="time">{{'ISHA'|translate}}</li>
                                                                <li *ngIf="prayerTime" class="time_text">
                                                                        {{prayerTime?.isha}}
                                                                </li>
                                                                <li *ngIf="!prayerTime" class="time_text">-</li>
                                                        </ul>
                                                </div>
                                        </div>

                                        <!-- <div class="item col-lg-2 col-md-2 r-imsakiye" style="width:163px;">
                                                <div class="hour">
                                                        <ul>
                                                                <li class="time imsakiye"><a style="color: #fff;"
                                                                                href="../../../../assets/adv/imsakiye.pdf"
                                                                                target="_blank">Ramazan İmsakiyesi</a>
                                                                </li>
                                                        </ul>
                                                </div>
                                        </div> -->
                                </div>
                        </div>
                </div>

        </header>
</section>


<div class="card flex justify-content-center">
        <p-dialog [modal]="true" header="Bağış Hesapları" [(visible)]="isVisible" [breakpoints]="{ '960px': '95vw' }"
                [style]="{ width: '60vw' }" [draggable]="false" [resizable]="false" [maximizable]="true">
                <p-table class="bankInfo-table" [value]="dataSource" [tableStyle]="{'min-width': '50rem'}">
                        <ng-template pTemplate="header" let-columns>
                                <tr>
                                        <th>Bağış Türü </th>
                                        <th>Hesap Adı</th>
                                        <th>BSB</th>
                                        <th>ACC</th>
                                        <th>Fiyat</th>
                                        <th>Açıklama</th>
                                </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-data let-columns="columns">
                                <tr>
                                        <td *ngIf="data.title; else noTitle">{{data.title}}</td>
                                        <td *ngIf="!data.title"><i class="fa-solid fa-dash"></i></td>

                                        <ng-template #noTitle>
                                                <td><i class="fa-solid fa-dash"></i></td>
                                        </ng-template>

                                        <td *ngIf="data.accountName; else noAccountName">{{data.accountName}}</td>
                                        <ng-template #noAccountName>
                                                <td><i class="fa-solid fa-dash"></i></td>
                                        </ng-template>

                                        <td *ngIf="data.bsb; else noBsb">{{data.bsb}}</td>
                                        <ng-template #noBsb>
                                                <td><i class="fa-solid fa-dash"></i></td>
                                        </ng-template>

                                        <td *ngIf="data.acc; else noAcc">{{data.acc}}</td>
                                        <ng-template #noAcc>
                                                <td><i class="fa-solid fa-dash"></i></td>
                                        </ng-template>

                                        <td *ngIf="data.amount; else noAmount">{{data.amount}}</td>
                                        <ng-template #noAmount>
                                                <td><i class="fa-solid fa-dash"></i></td>
                                        </ng-template>

                                        <td *ngIf="data.description; else noDescription">{{data.description}}</td>
                                        <ng-template #noDescription>
                                                <td><i class="fa-solid fa-dash"></i></td>
                                        </ng-template>


                                </tr>
                        </ng-template>
                </p-table>
        </p-dialog>
</div>