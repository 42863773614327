<app-header-style-six></app-header-style-six>



<div class="page-title-area page-title-style-two pages-bg2">
    <div class="container">

        <div class="page-title-content">
            <h2>Yönetim Kurulu</h2>
        </div>
    </div>
</div>

<div class="instructor-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6" *ngFor="let item of chairmans">
                <a>
                    <div class="single-instructor-box mb-30">
                        <div class="image">
                            <img [src]="photoUrl+item.image" alt="image">
                        </div>
                        <div class="content">
                            <h3><a>{{item.fullName}}</a></h3>
                            <span>{{item.title}}</span>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>

<app-header-style-two></app-header-style-two>