<app-header-style-six></app-header-style-six>

<app-homeeleven-main-banner></app-homeeleven-main-banner>

<!-- <app-services></app-services> -->
<div class="contact-info-area pt-90 pb-90">
    <div class="contact-info-area pt-100 pb-70">

        <div class="contact-info-area">
            <div class="container boxes">
                <div class="row res-box ">
                    <!-- <div class="col-lg-2 box">
                        <a href="/" target="_blank">
                            <div class="contact-info-box mb-30">
                                <div class="icon">
                                    <img src="assets/homeIcon/IconCenazeFonu.png" alt="">
    
                                </div>
                                <h3>Cenaze Fonu</h3>
                            </div>
                        </a>
                    </div> -->
                    <div [routerLink]="['/hac-kayit', this.hajj]" class="col-lg-2 box">
                        <div class="contact-info-box mb-30">
                            <div class="icon">
                                <img src="assets/homeIcon/hac.svg" alt="">


                            </div>
                            <h3>{{'HAJJ_REGISTRATIONS'|translate}}</h3>
                        </div>
                    </div>
                    <div [routerLink]="['/umre-kayit', this.umrah]" class="col-lg-2 col-md-6 col-sm-6 box">
                        <div class="contact-info-box mb-30">
                            <div class="icon">
                                <img src="assets/homeIcon/umre.svg" alt="">

                            </div>
                            <h3>{{'UMRAH_REGISTRATIONS'|translate}}</h3>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-6 col-sm-6 box">
                        <div [routerLink]="['/kurban', this.victim]" class="contact-info-box mb-30">
                            <div class="icon">
                                <img src="assets/homeIcon/kurban.svg" alt="">
                            </div>
                            <br>
                            <h3>{{'SACRIFICE'|translate}}</h3>
                        </div>
                    </div>
                    <div [routerLink]="['/zekat', this.alms]" class="col-lg-2 col-md-6 col-sm-6 box">
                        <div class="contact-info-box mb-30">
                            <div class="icon">
                                <img src="assets/homeIcon/zekat.svg" alt="">
                            </div>
                            <h3>{{'ZAKAT'|translate}}/{{'FITRAH'|translate}}</h3>
                        </div>
                    </div>

                   


                    <div routerLink="/camiler" class="col-lg-2 col-md-6 col-sm-6 box">
                        <div class="contact-info-box mb-30">
                            <div class="icon">
                                <img src="assets/homeIcon/camiler.svg" alt="">

                            </div>
                            <h3>{{'MOSQUES'|translate}}</h3>
                        </div>
                    </div>
                    
                    <div class="col-lg-2 col-md-6 col-sm-6 box">
                        <a [routerLink]="['/bhy', this.bhy]">
                            <div class="contact-info-box mb-30">
                                <div class="icon">
                                    <img src="assets/homeIcon/bhy.svg" alt="">

                                </div>
                                <h3>{{'RAISING_HAFIZ'|translate}}</h3>
                            </div>
                        </a>
                    </div>
                    <!-- <div [routerLink]="[clergy[0]?.frontMenuRecords[0]?.slug + '/1']"
                        class="col-lg-2 col-md-6 col-sm-6 box">
                        <div class="contact-info-box mb-30">
                            <div class="icon">
                                <img src="assets/homeIcon/IconGorevli.png" alt="">
                            </div>
                            <h3>Din Görevlileri</h3>
                        </div>
                    </div> -->
                    <!-- <div class="col-lg-2 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                        <div class="contact-info-box mb-30">
                            <div class="icon">
                                <i class='bx bxs-volume-full'></i>
                            </div>
                            <h3>Hutbeler</h3>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                        <div class="contact-info-box mb-30">
                            <div class="icon">
                                <i class='bx bxs-image'></i>
                            </div>
                            <h3>Galeri</h3>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
    <div class="blog-area pb-70">
        <div class="container">
            <div class="section-title text-start">
                <!-- <span class="sub-title">Son Dakika</span> -->
                <h2>{{'NEWS'|translate}}</h2>
                <a routerLink="/haberler" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span
                        class="label">{{'ALL_NEWS'|translate}}</span><i class="bx bx-book-reader icon-arrow after"></i></a>
            </div>


            <div class="blog-slides">
                <owl-carousel-o [options]="blogSlides">
                    <ng-template carouselSlide *ngFor="let item of news; let i =index;">
                        <div class="single-blog-post mb-30">
                            <div class="post-image">
                                <a [routerLink]="['/haber-detay',item?.code]" class="d-block">
                                    <img class="news-img" [src]="photoUrl+item?.frontAnnouncementRecords[0]?.fileUrl"
                                        alt="image">
                                </a>
                                <!-- <div class="tag">
                                    <a routerLink="/"> Haber {{i+1}}</a>
                                </div> -->
                            </div>
                            <div class="post-content">
                                <!-- <ul class="post-meta">

                                    <li><a
                                            routerLink="haberler">{{item?.frontAnnouncementRecords[0].longDescription}}</a>
                                    </li>
                                </ul> -->
                                <h3><a [routerLink]="['/haber-detay',item?.code]"
                                        class="d-inline-block">{{item?.frontAnnouncementRecords[0]?.title}}</a></h3>
                                <a [routerLink]="['/haber-detay/',item?.code]" class="read-more-btn">{{'READ_MORE'|translate}} <i
                                        class='bx bx-right-arrow-alt'></i></a>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>








        </div>
    </div>
    <app-header-style-two></app-header-style-two>
</div>
<!-- <app-funfacts-feedback></app-funfacts-feedback> -->

<!-- <section class="faq-area bg-f8e8e9 pb-100">
    <app-faq></app-faq>
    <div class="divider bg-f9f9f9"></div>
</section> -->

<!-- <app-homeeight-events></app-homeeight-events> -->

<!-- <app-premium-access></app-premium-access> -->