<app-header-style-six></app-header-style-six>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <!-- <ul>
                <li><a href="/">Anasayfa</a></li>
                <li>{{pages.name}}</li>
            </ul> -->
            <h2>Haberler</h2>
        </div>
    </div>
</div>

<div class="courses-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let item of news">
                <div class="single-courses-box mb-30">
                    <div class="courses-image">
                        <a [routerLink]="['/haber-detay',item?.code]" class="d-block"><img
                                [src]="photoUrl+item?.frontAnnouncementRecords[0].fileUrl" alt="image"></a>
                    </div>
                    <div class="courses-content">

                        <h3><a [routerLink]="['/haber-detay',item?.code]"
                                class="d-inline-block">{{item?.frontAnnouncementRecords[0]?.title}}</a></h3>

                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                {{item?.modifiedDate | date:'dd/MM/yyyy'}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="pagination-area text-center">
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/" class="page-numbers">2</a>
                    <a routerLink="/" class="page-numbers">3</a>
                    <a routerLink="/" class="page-numbers">4</a>
                    <a routerLink="/" class="page-numbers">5</a>
                    <a routerLink="/" class="next page-numbers"><i class='bx bx-chevron-right'></i></a>
                </div>
            </div> -->
        </div>

    </div>
</div>


<!-- 
<div class="col-lg-4 col-md-6" *ngFor="let item of news">
                <div class="haber-detay-post mb-30">
                    <div class="post-image">
                        <a [routerLink]="['/haber-detay',item?.code]" class="d-block">
                            <img [src]="item.img" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <ul class="post-meta">
                            <li><a routerLink="/haber-detay">Aralık 30, 2023</a></li>
                        </ul>
                        <h3><a routerLink="/haber-detay" class="d-inline-block">{{item.title}}</a></h3>
                        <p>{{item.content}}</p>
                        <a routerLink="/haber-detay" class="read-more-btn">Devamı <i
                                class='bx bx-right-arrow-alt'></i></a>
                    </div>
                </div>
            </div> -->