import { services } from './../../services/services';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, FormBuilder, AbstractControl, Validators, FormControl } from '@angular/forms';
import { Application } from 'src/app/models/Application';
import Swal from 'sweetalert2';
import { ApplicationService } from '../products-details-page/application.service';
import { HttpErrorResponse } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';


@Component({
    selector: 'app-faq-page',
    templateUrl: './faq-page.component.html',
    styleUrls: ['./faq-page.component.scss']
})
export class FaqPageComponent implements OnInit {
    //Umre

    mainForm: UntypedFormGroup;
    personalInformationForm: UntypedFormGroup;
    addressForm: UntypedFormGroup;
    otherForm: UntypedFormGroup;
    passportForm: UntypedFormGroup;
    association: any[] = [];
    airports: any[] = [];
    roomTypes: any[] = [];
    application: Application[] = [];
    isValid: boolean = false;
    hajjDetail: any;
    umrahDetail: any;
    isLoading: boolean = false;


    gender = [
        { id: 1, name: "Kadın" },
        { id: 2, name: "Erkek" }
    ]

    maritalStatus = [
        { id: 1, name: "Evli" },
        { id: 2, name: "Bekar" }
    ]

    constructor(private fb: FormBuilder, private applicationService: ApplicationService, private services: services, private router: Router) { }

    ngOnInit(): void {
        this.getAssociations();
        this.createForm();
        this.getAirport();
        this.getRoomTypes();
        // this.mainForm.get('roomTypeId').setValue('0');
        // this.mainForm.get('closestAssociationId').setValue('0');
        // this.personalInformationForm.get('genderId').setValue('0');
        // this.personalInformationForm.get('maritalStatusId').setValue('0');
        // this.otherForm.get('departureAirportId').setValue('0');
        // this.otherForm.get('landingAirportId').setValue('0');
        this.getAllFrontPage();

    }




    getAllFrontPage() {
        this.services.getAllFrontPages().subscribe((data: any) => {
            this.umrahDetail = data[8].code;
        })
    }

    getAssociations() {
        this.applicationService.getAllAssociations().subscribe((data: any) => {
            this.association = data;
        });
    }

    getAirport() {
        this.applicationService.getAllAirports().subscribe((data: any) => {
            this.airports = data;
        });
    }

    getRoomTypes() {
        this.applicationService.getAllRoomTypes().subscribe((data: any) => {
            this.roomTypes = data;
        });
    }


    checkForm(form: AbstractControl): boolean {
        this.isValid = form.dirty && form.valid && form.touched;
        return this.isValid;

    }

    nextTab(currentTabIndex: number) {
        currentTabIndex++;
        const tabId = `tab${currentTabIndex + 1}`;
        const tabRadio = document.getElementById(tabId) as HTMLInputElement;
        if (tabRadio) {
            tabRadio.click();
        }

    }

    createForm() {
        this.mainForm = this.fb.group({
            roomTypeId: ['', [Validators.required]],
            closestAssociationId: ['', [Validators.required]]
        })

        this.personalInformationForm = this.fb.group({
            name: ['', [Validators.required]],
            surname: ['', [Validators.required]],
            turkeyIdentificationNumber: ['', [Validators.required]],
            genderId: ['', [Validators.required]],
            dateOfBirth: ['', [Validators.required]],
            swedenIdentificationNumber: ['', [Validators.required]],
            motherName: ['', [Validators.required]],
            fatherName: ['', [Validators.required]],
            placeOfBirth: ['', [Validators.required]],
            maritalStatusId: ['', [Validators.required]],
            nationality: ['', [Validators.required]],
            headshotPicture: [''],
            phoneNumber: ['', [Validators.required]]

        })

        this.addressForm = this.fb.group({
            address: ['', [Validators.required]],
            city: ['', [Validators.required]],
            country: ['', [Validators.required]],
            postCode: ['', [Validators.required]]

        })

        this.passportForm = this.fb.group({
            passportNumber: ['', [Validators.required]],
            passportGivenDate: ['', [Validators.required]],
            passportExpirationDate: ['', [Validators.required]],
            passportPicture: [''],
            passportGivenPlace: ['', [Validators.required]]
        })

        this.otherForm = this.fb.group({
            departureAirportId: ['', [Validators.required]],
            landingAirportId: ['', [Validators.required]],
            explanation: ['']
        })
    }


    checkPassportExpirationDate() {
        const passportExpiration = this.passportForm?.get('passportExpirationDate').value;

        if (passportExpiration) {
            const passportExpirationDate = new Date(passportExpiration);
            const formatpasportexpiration = formatDate(passportExpirationDate, 'dd-MM-yyyy', 'en-US');
            const currentDate = new Date(); //şimdiki zaman
            const formatcurrentDate = formatDate(currentDate, 'dd-MM-yyyy', 'en-US');

            const eightMonthsFromNow = new Date();
            eightMonthsFromNow.setMonth(currentDate.getMonth() + 8);
            eightMonthsFromNow.setDate(currentDate.getDate() - 1);
            const formateightmonthsfromnow = formatDate(eightMonthsFromNow, 'dd-MM-yyyy', 'en-US');



            if (currentDate >= passportExpirationDate) {
                this.passportForm.get('passportExpirationDate').setErrors({ expired: true });

            }
            else if (eightMonthsFromNow >= passportExpirationDate) {
                this.passportForm.get('passportExpirationDate').setErrors({ tooSoon: true });

            }


        }
    }



    createMainBuildObject(): any {
        const application: any = {

            roomTypeId: this.mainForm.get('roomTypeId').value,
            closestAssociationId: this.mainForm.get('closestAssociationId').value,

            surname: this.personalInformationForm.get('surname').value.toUpperCase(),
            name: this.personalInformationForm.get('name').value.toUpperCase(),
            fatherName: this.personalInformationForm.get('fatherName').value.toUpperCase(),
            motherName: this.personalInformationForm.get('motherName').value.toUpperCase(),
            placeOfBirth: this.personalInformationForm.get('placeOfBirth').value.toUpperCase(),
            swedenIdentificationNumber: this.personalInformationForm.get('swedenIdentificationNumber').value,
            turkeyIdentificationNumber: this.personalInformationForm.get('turkeyIdentificationNumber').value,
            genderId: this.personalInformationForm.get('genderId').value,
            maritalStatusId: this.personalInformationForm.get('maritalStatusId').value,
            dateOfBirth: this.personalInformationForm.get('dateOfBirth').value,
            nationality: this.personalInformationForm.get('nationality').value.toUpperCase(),

            passportNumber: this.passportForm.get('passportNumber').value.toUpperCase(),
            passportGivenDate: this.passportForm.get('passportGivenDate').value,
            passportGivenPlace: this.passportForm.get('passportGivenPlace').value.toUpperCase(),
            passportExpirationDate: this.passportForm.get('passportExpirationDate').value,

            address: this.addressForm.get('address').value.toUpperCase(),
            city: this.addressForm.get('city').value.toUpperCase(),
            country: this.addressForm.get('country').value.toUpperCase(),

            phoneNumber: this.personalInformationForm.get('phoneNumber').value,

            departureAirportId: this.otherForm.get('departureAirportId').value,
            landingAirportId: this.otherForm.get('landingAirportId').value,

            explanation: this.otherForm.get('explanation').value.toUpperCase(),
            passportPicture: this.passportForm.get('passportPicture').value,
            headshotPicture: this.personalInformationForm.get('headshotPicture').value,
            postCode: this.addressForm.get('postCode').value

        };


        return application;
    }


    uploadFileEvent($event, type: any) {
        const selectedFile = $event.target.files[0];

        if (!selectedFile) {
            return;
        }

        const reader = new FileReader();

        reader.readAsDataURL(selectedFile);
        reader.onload = (_event) => {
            const file: any = {
                FormFile: selectedFile

            };


            const mimeType = selectedFile.type;

            if (!mimeType.match(/image\/*/)) {
                return;
            }

            const formData = new FormData();
            formData.append('FormFile', selectedFile);
            this.applicationService.addFile(formData)
                .subscribe(
                    (resp: any) => {
                        if (type == 'pass') {
                            this.passportForm.patchValue({
                                passportPicture: resp
                            })
                        }
                        if (type == 'head') {
                            this.personalInformationForm.patchValue({
                                headshotPicture: resp
                            })
                        }
                    },
                    (error: any) => {
                        // console.error(error);
                    }
                );


        };

    }

    saveForm() {
        this.isLoading = true;

        if (this.mainForm.valid && this.personalInformationForm.valid && this.addressForm.valid && this.otherForm.valid && this.passportForm.valid) {
            const application = this.createMainBuildObject();
            this.applicationService.addUmreForm(application).subscribe((data: any) => {
                this.isLoading = false;
                Swal.fire(
                    {
                        icon: 'success',
                        title: 'Başarılı',
                        text: 'Başvurunuz başarıyla gönderildi!',
                        confirmButtonText: 'Kapat'
                    }

                ).then((success) => {
                    this.router.navigateByUrl('/umre-bilgi/' + this.umrahDetail)
                });

            }, (error: any) => {
                this.isLoading = false;
                // console.error('servisten sonra hata', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Hata',
                    text: 'Başvurunuz sırasında bir hata oluştu!',
                    confirmButtonText: 'Kapat'

                }
                )
            }

            )
        }
        else {
            this.isLoading = false;
            Swal.fire({
                icon: 'error',
                title: 'Hata',
                text: 'Eksik alanları doldurunuz',
                confirmButtonText: 'Kapat'
            }
            )
        }


    }

}