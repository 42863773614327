import { services } from 'src/app/components/services/services';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  submenus: any;
  orphanMenus: any
  appSettings: any;
  address: any;
  telephone1: any;
  telephone2: any;
  fax: any;
  facebook: any;
  twitter: any;
  instagram: any;
  linkedin: any;
  youtube: any;
  email1: any;


  constructor(private service: services) { }

  ngOnInit(): void {
    this.getAllFrontMenus();
    this.getAppSettings();
  }


  getAllFrontMenus() {
    this.service.getAllFrontMenuRecord().subscribe((data: any) => {
      this.submenus = data.filter(x => x.parentId !== null && x.isActive == true && x.isDeleted == false && x.url).sort((a, b) => {
        return a.order - b.order;
      });

      this.orphanMenus = data.filter(menu => menu.parentId !== null && menu.isActive && menu.isDeleted == false && menu.url == "").sort((a, b) => {
        return a.order - b.order;
      });
      // console.log('submenus', this.submenus);
      // console.log('orphan', this.orphanMenus);

    })
  }

  getAppSettings() {
    this.service.getAppSetting().subscribe((data: any) => {
      this.appSettings = data;
      this.address = data.find(item => item.key === "adres")?.value;
      this.telephone1 = data.find(item => item.key === "telefon1")?.value;
      this.telephone2 = data.find(item => item.key === "telefon2")?.value;
      this.fax = data.find(item => item.key === "fax")?.value;
      this.facebook = data.find(item => item.key === "facebook")?.value;
      this.twitter = data.find(item => item.key === "twitter")?.value;
      this.instagram = data.find(item => item.key === "instagram")?.value;
      this.linkedin = data.find(item => item.key === "linkedin")?.value;
      this.youtube = data.find(item => item.key === "youtube")?.value;
      this.email1 = data.find(item => item.key === "email1")?.value;


    })
  }


}
