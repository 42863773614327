import { Component, OnInit } from '@angular/core';
import { services } from 'src/app/components/services/services';
@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent implements OnInit{


  service: any[] = [];
  activity: any[] = [];
  constructor(private services: services) { }

  ngOnInit(): void {
      this.services.getFrontAllActivity().subscribe((data: any) => {
          this.activity = data.filter(list => list.isActive == true);


      });
      this.services.getFrontAllService().subscribe((data: any) => {
          this.service = data.filter(list => list.isActive == true);


      });

  }

  selectedItem = null;

  toggleAccordionItem(item) {
    item.open = !item.open;
    if (this.selectedItem && this.selectedItem !== item) {
      this.selectedItem.open = false;
    }
    this.selectedItem = item;
  }
}
