
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class services {
    path = environment.apiUrl;
    languageCode: string = 'tr';
    constructor(
        private http: HttpClient) {
        this.languageCode = localStorage.getItem('language')
    }

    // ~~~~~~~~~~~~~~~~~~ Menu~~~~~~~~~~~~~~~~~~~~~ //

    getAllFrontMenuRecord(): Observable<any> {
        const url = `FrontMenu/GetList?languageCode=${this.languageCode}`;
        // console.log(url)
        return this.http.get(this.path + url);
    }

    getFrontMenuById(code: string): Observable<any> {
        const url = `FrontMenu/Get/${code}`;
        return this.http.get(this.path + url);

    }


    // ~~~~~~~~~~~~~~~~~~ Page~~~~~~~~~~~~~~~~~~~~~ //

    getAllFrontPages(): Observable<any> {
        const url = `FrontPage/GetList`;
        return this.http.get(this.path + url);

    }

    getAllFrontPageRecord(): Observable<any> {
        const url = `FrontPageRecord/GetList`;
        return this.http.get(this.path + url);

    }

    getFrontPageByCode(code: string): Observable<any> {
        const url = `FrontPage/GetList?languageCode=${this.languageCode}&code=${code}`;
        return this.http.get(this.path + url);

    }

    getFrontPageRecordByCode(code: string): Observable<any> {
        const url = `FrontPageRecord/Get/${code}`;
        return this.http.get(this.path + url);

    }
    // ~~~~~~~~~~~~~~~~~~ Announcement~~~~~~~~~~~~~~~~~~~~~ //

    getFrontAllAnnouncements(): Observable<any> {
        const url = `FrontAnnouncement/GetList?languageCode=${this.languageCode}`;
        return this.http.get(this.path + url);
    }

    getFrontAnnouncementByCode(code): Observable<any> {
        const url = `FrontAnnouncement/GetList?languageCode=${this.languageCode}&code=${code}`;
        return this.http.get(this.path + url);
    }

    // ~~~~~~~~~~~~~~~~~~ Clergy~~~~~~~~~~~~~~~~~~~~~ //

    getFrontAllClergy(): Observable<any> {
        const url = `Clergy/GetAll`;
        return this.http.get(this.path + url);

    }

    // ~~~~~~~~~~~~~~~~~~ Activity~~~~~~~~~~~~~~~~~~~~~ //

    getFrontAllActivity(): Observable<any> {
        const url = `Activity/GetAllActivities`;
        return this.http.get(this.path + url);

    }

    // ~~~~~~~~~~~~~~~~~~ Mosque~~~~~~~~~~~~~~~~~~~~~ //

    getFrontAllMosque(): Observable<any> {
        const url = `Mosque/GetAll`;
        return this.http.get(this.path + url);

    }


    // ~~~~~~~~~~~~~~~~~~ Service~~~~~~~~~~~~~~~~~~~~~ //

    getFrontAllService(): Observable<any> {
        const url = `Service/GetList`;
        return this.http.get(this.path + url);

    }

    // ~~~~~~~~~~~~~~~~~~ Foundation Pulication~~~~~~~~~~~~~~~~~~~~~ //

    getFrontAllPublication(): Observable<any> {
        const url = `FoundationPublication/GetAll`;
        return this.http.get(this.path + url);

    }

    // ~~~~~~~~~~~~~~~~~~ Funeral Fund~~~~~~~~~~~~~~~~~~~~~ //

    addFamily(member: any): Observable<any> {
        const url = "Family/FuneralFundApplication";
        return this.http.post<any>(this.path + url, member);
    }

    // ~~~~~~~~~~~~~~~~~~ Prayer Time~~~~~~~~~~~~~~~~~~~~~ //
    getFrontAllCountry(): Observable<any> {
        const url = `Country/GetList`;
        return this.http.get(this.path + url);
    }

    //ankara long:32.53 lat:39.57
    getPrayerTimes(long: number, lat: number) {
        const url = `PlaceAPI/GetByDailyCityId?longitude=${long}&latitude=${lat}`;
        return this.http.get('https://admin.awqatsalah.com/api/' + url);
    }

    getFrontCountryByLangcode(code): Observable<any> {
        const url = `Country/GetByLangcode?langcode=${code}`;
        return this.http.get(this.path + url);

    }

    getFrontCityById(id): Observable<any> {
        const url = `City/GetById?id=${id}`;
        return this.http.get(this.path + url);

    }

    // ~~~~~~~~~~~~~~~~~~App Settings~~~~~~~~~~~~~~~~~~~~~ //

    getAppSetting(): Observable<any> {
        const url = `AppSetting`;
        return this.http.get(this.path + url);
    }

    // ~~~~~~~~~~~~~~~~~~ Brochures (Broşürler)~~~~~~~~~~~~~~~~~~~~~ //

    getFrontAllBrochures(): Observable<any> {
        const url = `Brochure/GetList`;
        return this.http.get(this.path + url);

    }
    // ~~~~~~~~~~~~~~~~~~ Languages (Diller)~~~~~~~~~~~~~~~~~~~~~ //
    getFrontAllLanguages(): Observable<any> {
        const url = `Language/GetList`;
        return this.http.get(this.path + url);

    }
    // ~~~~~~~~~~~~~~~~~~ Chairman (Yönetim Kurulu)~~~~~~~~~~~~~~~~~~~~~ //

    getFrontAllChairman(): Observable<any> {
        const url = `Chairman/GetAll`;
        return this.http.get(this.path + url);

    }
    // ~~~~~~~~~~~~~~~~~~ Meals (Mealler)~~~~~~~~~~~~~~~~~~~~~ //

    getFrontAllMeals(): Observable<any> {
        const url = `Meal/GetList`;
        return this.http.get(this.path + url);

    }

    // ~~~~~~~~~~~~~~~~~~AccountInfo~~~~~~~~~~~~~~~~~~~~~ //

    getAccountInfo(): Observable<any> {
        const url = `AccountInfo/GetList`;
        return this.http.get(this.path + url);
    }
}