<app-header-style-six></app-header-style-six>

<div class="page-title-area page-title-style-two item-bg2">
    <div class="container">

        <div class="page-title-content">
            <h2>{{pages?.frontPage?.frontPageRecords[0]?.name}}</h2>
        </div>
    </div>
</div>


<div class="instructor-details-area pt-70 pb-70">
    <div class="container">
        <div class="instructor-details-desc">
            <div class="row" *ngIf="pageInfo?.fileUrl!=''">
                <div class="col-lg-4 col-md-4">
                    <div class="instructor-details-sidebar">
                        <img class="img" [src]="photoUrl+pageInfo?.fileUrl" alt="image">
                        <!-- <h4 style="margin-top:20px;">Prof.Dr. ADNAN BÜLENT BALOĞLU</h4> -->
                        <!-- ---- <ul class="social-link">
                            <li><a href="#" class="d-block facebook" target="_blank"><i class='bx bxl-facebook'></i></a>
                            </li>
                            <li><a href="#" class="d-block twitter" target="_blank"><i class='bx bxl-twitter'></i></a>
                            </li>
                            <li><a href="#" class="d-block instagram" target="_blank"><i
                                        class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a>
                            </li>
                            <li><a href="#" class="d-block pinterest" target="_blank"><i
                                        class='bx bxl-pinterest-alt'></i></a></li>
                        </ul> ---- -->
                    </div>
                </div>
                <div class="col-lg-8 col-md-8">
                    <div class="instructor-details">
                        <!-- <h3>{{pageInfo.name}}</h3>---- -->
                        <p [innerHTML]="pageInfo?.pageContent">
                        </p>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="pageInfo?.fileUrl==''">

                <div class="col-lg-12 col-md-12">
                    <div class="instructor-details">
                        <!-- <h3>{{pageInfo.name}}</h3>--- -->
                        <p [innerHTML]="pageInfo.pageContent">
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<app-header-style-two></app-header-style-two>