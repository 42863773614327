import { Component, OnInit } from '@angular/core';
import { services } from './../../services/services';
import { UntypedFormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-funeral-fund',
    templateUrl: './funeral-fund.html',
    styleUrls: ['./funeral-fund.css']
})
export class FuneralFundComponent implements OnInit {
    isLoading = false;
    mainForm: UntypedFormGroup
    spouseForm: UntypedFormGroup
    memberForm: UntypedFormGroup
    showSpouseForm: boolean = false
    showMemberForm: boolean = false


    gender = [
        { id: 1, name: "Kadın" },
        { id: 2, name: "Erkek" }
    ]

    nationality = [
        { id: 1, name: "T.C" },
        { id: 2, name: "Çifte Vatandaş" }
    ]

    constructor(private services: services,
        private formBuilder: FormBuilder
    ) { }

    ngOnInit(): void {
        this.createMainForm();
        this.createMemberForm();
        this.createSpouseForm();
    }



    createMainForm() {
        this.mainForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            identificationNumber: ['', Validators.required],
            birthPlace: ['', Validators.required],
            birthDay: ['', Validators.required],
            genderId: ['', Validators.required],
            nationality: ['', Validators.required],
            address: this.formBuilder.group({
                street: ['', Validators.required],
                postCode: ['', Validators.required],
                district: ['', Validators.required],
                phoneNumber: ['', Validators.required],
                email: ['', [Validators.required, Validators.email]]
            }),
            familyMembers: this.formBuilder.array([]),
        });
    }



    createSpouseForm() {
        this.spouseForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            identificationNumber: ['', Validators.required],
            birthPlace: ['', Validators.required],
            birthDay: ['', Validators.required],
            nationality: ['', Validators.required],
            genderId: ['', Validators.required],
            memberTypeId: [2]
        });
    }


    spouseFormShow() {
        this.showSpouseForm = !this.showSpouseForm;
    }

    showFamilyMemberForm() {
        this.showMemberForm = !this.showMemberForm;
    }

    createMemberForm() {
        this.memberForm = this.formBuilder.group({
            familyMembers: this.formBuilder.array([this.createFamilyMemberForm()])
        });
    }

    createFamilyMemberForm() {
        return this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            identificationNumber: ['', Validators.required],
            birthPlace: ['', Validators.required],
            birthDay: ['', Validators.required],
            genderId: ['', Validators.required],
            memberTypeId: [3]
        });
    }


    get familyMembers() {
        return this.memberForm.get('familyMembers') as FormArray;
    }

    addNewFamilyMember() {
        this.familyMembers.push(this.createFamilyMemberForm());
    }

    saveForm() {

        this.isLoading = true;

        const formData = {
            mainForm: this.mainForm.value,
        };

        if (this.spouseForm.valid && this.showSpouseForm) {
            formData.mainForm.familyMembers.push(this.spouseForm.value);
        }

        if (this.showMemberForm && this.memberForm.valid) {
            this.memberForm.value.familyMembers.forEach((x) => formData.mainForm.familyMembers.push(x));
        }

        this.services.addFamily(formData.mainForm).subscribe((data: any) => {

            this.isLoading = false;

            if (data.success == true) {
                Swal.fire(
                    {
                        icon: 'success',
                        title: 'Başarılı',
                        text: 'Başvurunuz başarıyla gönderildi!',
                        confirmButtonText: 'Kapat'
                    }
                ).then((result) => {
                    if (result.isConfirmed) {
                        location.reload(); 
                    }
                });

            }
            else {
                const errorMessage = data.errors[0]
                Swal.fire(
                    {
                        icon: 'error',
                        title: 'Başarısız',
                        text: errorMessage,
                        confirmButtonText: 'Kapat'
                    }
                )
                if (this.spouseForm.valid && this.showSpouseForm) {
                    formData.mainForm.familyMembers.pop();
                }
                if (this.showMemberForm && this.memberForm.valid) {
                    this.memberForm.value.familyMembers.forEach((x) => formData.mainForm.familyMembers.pop());
                }
            }
        }, (error: any) => {
            if (this.spouseForm.valid && this.showSpouseForm) {
                formData.mainForm.familyMembers.pop();
            }
            if (this.showMemberForm && this.memberForm.valid) {
                this.memberForm.value.familyMembers.forEach((x) => formData.mainForm.familyMembers.pop());
            }
            this.isLoading = false;
            if (error && error.errors && error.errors.length > 0) {
                const errorMessage = error.errors[0];

                Swal.fire(
                    {
                        icon: 'error',
                        title: 'Başarısız',
                        text: errorMessage,
                        confirmButtonText: 'Kapat'
                    }
                )
            } else {
                Swal.fire(
                    {
                        icon: 'error',
                        title: 'Başarısız',
                        text: 'Başvurunuz sırasında bir hata oluştu!',
                        confirmButtonText: 'Kapat'
                    }
                )
            }
        });
    }




}
