<!-- <div class="gym-home-slides">
    <owl-carousel-o [options]="gymHomeSlides">
        <ng-template carouselSlide *ngFor="let slide of sliders ; let i = index">
            <img [src]="slide?.img" alt="">
            <div class="container">
                <div class="gym-banner-content">

                </div>
            </div>
        </ng-template>



    </owl-carousel-o>
</div> -->

<div class="gym-home-slides">
    <p-skeleton *ngIf="!slider" width="100%" height="850px"></p-skeleton>

    <owl-carousel-o *ngIf="slider" [options]="gymHomeSlides">
        <ng-template carouselSlide *ngFor="let slide of slider; let i = index">
            <img [src]=" photoUrl + slide.frontAnnouncementRecords[0]?.fileUrl " alt="">
            <!-- <div [ngClass]="'bg-' + (i + 1)" [ngStyle]="{ 'background-image': 'url(' + photoUrl + slide.frontAnnouncementRecords[0]?.fileUrl + ')' }"> -->

            <div class="container">
                <div class="gym-banner-content">

                </div>
            </div>
            <!-- </div> -->
        </ng-template>

    </owl-carousel-o>
</div>