<app-header-style-six></app-header-style-six>

<div class="page-title-area page-title-style-two item-bg2">
    <div class="container">
        <div class="page-title-content">
            <h2>{{'MOSQUES'|translate}}</h2>
        </div>
    </div>
</div>


<app-homesix-courses></app-homesix-courses>
<app-header-style-two></app-header-style-two>