import { Component, OnInit } from '@angular/core';
import { services } from './../../services/services';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  pages: any;
  photoUrl: string = environment.serverUrl;
  news: any[] = [];
  constructor(private services: services) { }

  ngOnInit(): void {
    this.getAllNews();
  }

  getAllNews() {
    this.services.getFrontAllAnnouncements().subscribe((data: any) => {
      this.news = data.filter(news => news.isNews == true && news.isDeleted == false && news.isActive == true).sort((a, b) => {
        return a.order - b.order;
      });
    });
  }
}