import { Component, OnInit } from '@angular/core';
import { services } from '../../services/services';

export interface Account {
  title: string;
  accountName: string;
  bsb: string;
  acc: string;
  amount: string;
  description: string;
}

@Component({
  selector: 'app-our-values',
  templateUrl: './our-values.component.html',
  styleUrls: ['./our-values.component.scss']
})
export class OurValuesComponent implements OnInit {
  displayedColumns: string[] = ['title', 'accountName', 'bsb', 'acc', 'amount', 'description'];
  dataSource: Account;
  constructor(private service: services) { }

  ngOnInit(): void {
    this.getAccounts();
  }

  getAccounts() {
    this.service.getAccountInfo().subscribe((res: any) => {
      // console.log('bank accounts', res);
      this.dataSource = res;
    })
  }

}
