import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { services } from './../../services/services';

@Component({
  selector: 'app-home-page-six',
  templateUrl: './home-page-six.component.html',
  styleUrls: ['./home-page-six.component.scss']
})
export class HomePageSixComponent implements OnInit {

  mosques: any[] = [];
  photoUrl: string = environment.serverUrl
  constructor(private services: services) { }

  ngOnInit(): void {
      this.services.getFrontAllMosque().subscribe((data: any) => {
          this.mosques = data;
          // console.log('camiler', this.mosques);
      });
  }

  // for tab click event
  currentTab = 'tab1';
  switchTab(event: MouseEvent, tab: string) {
      event.preventDefault();
      this.currentTab = tab;
  }



}
