<h2 mat-dialog-title>BAĞIŞ HESAPLARI</h2>
<mat-dialog-content class="mat-typography">
    <table mat-table [dataSource]="dataSource" class="responsive-table">
        <thead>
            <tr>
                <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef class="bold"> Bağış Türü </th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.title">{{element.title}}</ng-container>
                        <ng-container *ngIf="!element.title"><i class="fa-solid fa-dash"></i></ng-container>
                    </td>
                </ng-container>
            </tr>
        </thead>
        <tbody>
            <tr>
                <ng-container matColumnDef="accountName">
                    <th mat-header-cell *matHeaderCellDef class="bold"> Hesap Adı </th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.accountName">{{element.accountName}}</ng-container>
                        <ng-container *ngIf="!element.accountName"><i class="fa-solid fa-dash"></i></ng-container>
                    </td>
                </ng-container>
            </tr>
            <tr>
                <ng-container matColumnDef="bsb">
                    <th mat-header-cell *matHeaderCellDef class="bold"> BSB</th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.bsb">{{element.bsb}}</ng-container>
                        <ng-container *ngIf="!element.bsb"><i class="fa-solid fa-dash"></i></ng-container>
                    </td>
                </ng-container>
            </tr>
            <tr>
                <ng-container matColumnDef="acc">
                    <th mat-header-cell *matHeaderCellDef class="bold"> ACC </th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.acc">{{element.acc}}</ng-container>
                        <ng-container *ngIf="!element.acc"><i class="fa-solid fa-dash"></i></ng-container>
                    </td>
                </ng-container>
            </tr>
            <tr>
                <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef class="bold"> Fiyat </th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.amount">{{element.amount}}</ng-container>
                        <ng-container *ngIf="!element.amount"><i class="fa-solid fa-dash"></i></ng-container>
                    </td>
                </ng-container>
            </tr>
            <tr>
                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef class="bold"> Açıklama </th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.description">{{element.description}}</ng-container>
                        <ng-container *ngIf="!element.description"><i class="fa-solid fa-dash"></i></ng-container>
                    </td>
                </ng-container>
            </tr>
        </tbody>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>


</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Kapat</button>
</mat-dialog-actions>