import { services } from 'src/app/components/services/services';
import { Component, HostListener, OnInit } from '@angular/core';
import { prayerTime } from 'src/app/models/prayerTime';
import { environment } from 'src/environments/environment';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    chairmans: any[];
    photoUrl: string = environment.serverUrl;
    constructor(private services: services) {

    }
    ngOnInit(): void {
        this.getAllChairmans();
    }

    getAllChairmans() {
        this.services.getFrontAllChairman().subscribe((res: any) => {
            this.chairmans = res.slice().sort((a, b) => a.order - b.order);
            // console.log('yönetim kurulu:', this.chairmans);
        });
    }


}
