import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { services } from '../../../services/services';

@Component({
  selector: 'app-homeeight-blog',
  templateUrl: './homeeight-blog.component.html',
  styleUrls: ['./homeeight-blog.component.scss']
})
export class HomeeightBlogComponent implements OnInit {
  pages: any;
  pageInfo: any;
  photoUrl: string = environment.serverUrl;
  list: any[] = [];
  // isLoading: boolean = false

  constructor(private activeRoute: ActivatedRoute, private services: services) {

  }

  ngOnInit(): void {
    this.getTranslations();
  }
  getTranslations() {
    this.services.getFrontAllMeals().subscribe((res: any) => {
      // console.log('mealler: ', res);
      this.list = res;
    })
  }

}
