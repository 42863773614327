import { environment } from 'src/environments/environment';
import { services } from './../../services/services';

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-instructors-page-two',
  templateUrl: './instructors-page-two.component.html',
  styleUrls: ['./instructors-page-two.component.scss']
})
export class InstructorsPageTwoComponent implements OnInit {
  list: any[] = []
  photoUrl: string = environment.serverUrl
  showFullText: boolean = false;

  constructor(private services: services) { }

  ngOnInit(): void {
    this.services.getFrontAllClergy().subscribe((data: any) => {
      this.list = data.sort((a, b) => { return a.order - b.order; });
      // console.log(this.list);

    });

  }


}
