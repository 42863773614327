<app-header-style-six></app-header-style-six>


<div class="page-title-area page-title-style-two item-bg2">
    <div class="container">
        <div class="page-title-content">
            <h2>Din Görevlilerimiz</h2>
        </div>
    </div>
</div>

<div class="events-area pt-100 pb-70">
    <div class="container">
        <div class="single-events-box mb-30" *ngFor="let item of list">
            <div class="events-box">
                <div class="events-image">
                    <div class="image bg1">
                        <img *ngIf="item?.image" [src]="photoUrl+item.image" alt="image">
                        <img *ngIf="!item?.image" src="../../../../assets/img/advPhotos/advLogo.png" alt="image">
                    </div>
                </div>
                <div class="events-content">
                    <div class="content">
                        <h3><a>{{item.jobDescription}}</a></h3>
                        <p>{{item.placeOfDuty}}</p>
                        <!-- <span class="location"><i class='bx bx-map'></i> 120 G-35 Spinsovila Sc, USA</span> -->
                        <a class="join-now-btn">{{item.name}} {{item.surname}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-header-style-two></app-header-style-two>