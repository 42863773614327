import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment';
import { services } from './../../services/services';
@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  photoUrl: string = environment.serverUrl
  newsDetail: any;
  news: any;
  constructor(private activeRoute: ActivatedRoute, private datePipe: DatePipe, private services: services) {
    this.services.getFrontAllAnnouncements().subscribe((data: any) => {
      this.news = data.filter(news => news?.isNews == true && news?.isDeleted == false && news?.isActive == true).sort((a, b) => {
        return a.order - b.order;
      });
    // console.log('haberler', this.news);
    });
  }


  ngOnInit() {
    this.activeRoute.data.subscribe(
      (data: { news: any }) => {
        if (data.news) {
          this.newsDetail = data.news[0];
          // console.log('haber içerik', this.newsDetail);
        }
      });
  }


}
