<app-header-style-six></app-header-style-six>

<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="/">{{'HOME'|translate}}</a></li>
                <li>{{'CONTACT'|translate}}</li>
            </ul>
            <h2>{{'CONTACT'|translate}}</h2>
        </div>
    </div>
</div>

<div class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-envelope'></i>
                    </div>
                    <h3>{{'EMAIL'|translate}}</h3>
                    <p><a href="mailto:{{email1}}">{{email1}}</a></p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-map'></i>
                    </div>
                    <h3>{{'ADDRESS'|translate}}</h3>
                    <p><a rel="noopener"
                            href="https://www.google.com/maps/dir/39.9674521,32.7971643/161+Auburn+Rd,+Auburn+NSW+2144,+Avustralya/@0.2305044,6.2373852,3z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x6b12bcbbbf6f8f7b:0x862e3e886dc40f42!2m2!1d151.0311933!2d-33.8576305?entry=ttu"
                            target="_blank">{{address}}</a></p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>
                    <h3>{{'PHONE'|translate}}</h3>
                    <p><a href="tel:{{telephone1}}">{{telephone1}}</a></p>
                </div>
            </div>
        </div>
    </div>
</div>


<h1 class="fancy">
    <div class="rounded-social-buttons">
        <a class="social-button facebook" href="{{facebook}}" target="_blank"><i class="fab fa-facebook-f"></i></a>
        <!-- <a class="social-button twitter" href="{{twitter}}" target="_blank"><i class="fab fa-twitter"></i></a> -->
        <!-- <a class="social-button linkedin" href="https://www.linkedin.com/" target="_blank"><i
                class="fab fa-linkedin"></i></a> -->
        <!-- <a class="social-button tiktok" href="https://www.tiktok.com/" target="_blank"><i class="fab fa-tiktok"></i></a> -->
        <!-- <a class="social-button youtube" href="{{youtube}}" target="_blank"><i class="fab fa-youtube"></i></a> -->
        <a class="social-button instagram" href="{{instagram}}" target="_blank"><i class="fab fa-instagram"></i></a>
    </div>
</h1>
<!-- <div class="contact-area pb-100">
    <div class="container">
        <div class="section-title">
            <h2 style="margin-top: 10px;">İletişim Formu</h2>
        </div>
        <div class="contact-form">
            <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel"
                                class="form-control" id="name" placeholder="İsim giriniz">
                            <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                <div *ngIf="name.errors.required">İsim zorunludur.</div>
                                <div *ngIf="name.errors.minlength">İsim en az {{
                                    name.errors.minlength.requiredLength }} karakterdir.</div>
                                <div *ngIf="name.errors.maxlength">İsin en fazla 50 karakterdir.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required ngModel name="email" type="text" #email="ngModel" class="form-control"
                                id="email" placeholder="E-Posta giriniz">
                            <div class="alert alert-danger" *ngIf="email.touched && !email.valid">E-Posta zorunludur.</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required ngModel name="number" type="text" #number="ngModel" class="form-control"
                                id="number" placeholder="Telefon">
                            <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Telefon zorunludur.</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required ngModel name="subject" type="text" #subject="ngModel" class="form-control"
                                id="subject" placeholder="Konu giriniz">
                            <div class="alert alert-danger" *ngIf="subject.touched && !subject.valid">Zorunlu alan.</div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="5"
                                class="form-control" placeholder="Mesajınız..."></textarea>
                            <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Zorunlu alan.
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid"><i
                                class='bx bx-paper-plane icon-arrow before'></i><span class="label">Gönder</span><i class="bx bx-paper-plane icon-arrow after"></i></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="contact-bg-image"><img src="assets/img/map.png" alt="image"></div>
</div> -->

<div id="map">
    <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d207.07290543629117!2d151.030937!3d-33.8596!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12bcbc0dbd1217%3A0x846540b19132fb83!2s191%20Auburn%20Rd%2C%20Auburn%20NSW%202144%2C%20Avustralya!5e0!3m2!1str!2str!4v1709111238690!5m2!1str!2str"
        width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>
<app-header-style-two></app-header-style-two>