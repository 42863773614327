<app-header-style-six></app-header-style-six>
<div class="page-title-area page-title-style-two item-bg2">
    <div class="container">

        <div class="page-title-content">
            <h2>Mealler</h2>
        </div>
    </div>
</div>


<section class="courses-area pt-70 pb-50">
    <div class="container">
        <div class="section-title text-start">
            <div class="row">
                <div class="col-lg-4 col-md-6" *ngFor="let item of list">
                    <div class="single-courses-box mb-30">
                        <div class="courses-image">
                            <a *ngIf="item?.linkImage && item?.linkUrl=='' " [href]="photoUrl+item?.linkImage "
                                target="_blank" class="d-block" target="_blank"><img [src]="photoUrl+item?.image"
                                    alt="image"></a>

                            <a *ngIf="item?.linkUrl && item?.linkImage==''" [href]="item?.linkUrl " class="d-block"
                                target="_blank"><img [src]="photoUrl+item?.image" alt="image"></a>
                        </div>
                        <div *ngIf="item?.linkUrl!='' || item?.linkImage!=''" class="courses-content">
                            <div class="course-author d-flex align-items-center">
                                <!-- <img src="assets/img/user1.jpg" class="rounded-circle mr-2" alt="image"> -->
                                <span>{{item?.author}}</span>
                            </div>
                            <h3><a *ngIf="item?.linkImage && item?.linkUrl=='' " [href]="photoUrl+item?.linkImage "
                                    target="_blank" class="d-inline-block">{{item?.title}}</a> </h3>
                            <h3><a *ngIf="item?.linkUrl && item?.linkImage==''" [href]="item?.linkUrl" target="_blank"
                                    class="d-inline-block">{{item?.title}}</a> </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<app-header-style-two></app-header-style-two>