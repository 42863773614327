<app-header-style-six></app-header-style-six>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <h2>Camilerimiz</h2>
        </div>
    </div>
</div>
<div class="courses-area pt-70 pb-70">
    <div class="container">
        <div class="courses-list-tab">

            <div class="tabs-container">
                <div class="pane" id="tab3" *ngIf="currentTab === 'tab1'">


                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-12 col-md-12" *ngFor="let list of mosques">
                                    <div class="single-courses-list-box mb-30">
                                        <div class="box-item">
                                            <div class="courses-image">
                                                <div class="image bg-1">
                                                    <img [src]="photoUrl+list.image" class="img-mosque" alt="image">
                                                </div>
                                            </div>
                                            <div class="courses-desc">
                                                <div class="courses-content">

                                                    <h3>{{list?.name}}</h3>
                                                    <p><span><b>{{'ADDRESS'|translate}}:</b> {{list?.address}}</span>
                                                    </p>
                                                    <p *ngIf="list.website" style="display: flex; flex-direction: row;">
                                                        <b>{{'WEBSITE'|translate}}:</b> <span
                                                            [innerHTML]=" list?.website"></span>
                                                    </p>

                                                    <p *ngIf="list.explanationAboutMosque">
                                                        <b>Açıklama:</b> <span
                                                            [innerHTML]=" list?.explanationAboutMosque"></span>
                                                    </p>
                                                </div>
                                                <div class="courses-box-footer">
                                                    <ul>
                                                        <li *ngIf="list.email"><i class='bx bx-world'></i> <a>
                                                                {{list?.email}}</a></li>
                                                        <li *ngIf="list.phoneNumber"><i class='bx bx-phone'></i> <a
                                                                href="tel:{{list?.phoneNumber}}">{{list.phoneNumber}}</a>
                                                        </li>


                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <app-header-style-two></app-header-style-two>