import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { services } from '../../services/services';
@Component({
  selector: 'app-home-page-eleven',
  templateUrl: './home-page-eleven.component.html',
  styleUrls: ['./home-page-eleven.component.scss']
})
export class HomePageElevenComponent implements OnInit {
  pages: any;
  pageInfo: any;
  photoUrl: string = environment.serverUrl;
  list: any[] = [];
  // isLoading: boolean = false

  constructor(private activeRoute: ActivatedRoute, private services: services) {

  }

  ngOnInit(): void {
    this.getTranslations();
  }
  getTranslations() {
    this.services.getFrontAllActivity().subscribe((res: any) => {
      // console.log('faaliyetler: ', res);
      this.list = res;
    })
  }

}
