import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { services } from '../../services/services';

@Component({
  selector: 'app-about-page-one',
  templateUrl: './about-page-one.component.html',
  styleUrls: ['./about-page-one.component.scss']
})
export class AboutPageOneComponent implements OnInit {
  pages: any;
  pageInfo: any;
  photoUrl: string = environment.serverUrl;
  list: any[] = [];

  constructor(private activeRoute: ActivatedRoute, private services: services) {
  }

  ngOnInit(): void {
    this.getTranslations();
  }
  getTranslations() {
    this.services.getFrontAllBrochures().subscribe((res: any) => {
      // console.log('broşürler: ', res);
      this.list = res;
    })
  }
}

