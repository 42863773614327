import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { services } from './../../../services/services';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-homesix-courses',
    templateUrl: './homesix-courses.component.html',
    styleUrls: ['./homesix-courses.component.scss']
})
export class HomesixCoursesComponent implements OnInit {
    pages: any;
    photoUrl: string = environment.serverUrl;

    certificates: any[] = [
        { img: '/assets/adv/sertifika1.jpg' },
        { img: '/assets/adv/sertifika2.jpg' },
        { img: '/assets/adv/sertifika3.jpg' }
    ]

    constructor(private activeRoute: ActivatedRoute, private services: services) { }

    ngOnInit(): void {
        this.activeRoute.data.subscribe(
            (data: { pages: any }) => {
                if (data.pages) {
                    this.pages = data.pages;
                    // console.log(this.pages);

                }
            });
    }


}
