import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { services } from 'src/app/components/services/services';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-homeeleven-main-banner',
    templateUrl: './homeeleven-main-banner.component.html',
    styleUrls: ['./homeeleven-main-banner.component.scss']
})
export class HomeelevenMainBannerComponent implements OnInit {

    slider: any[] = [];
    photoUrl: string = environment.serverUrl

    constructor(private services: services) { }

    ngOnInit(): void {
        this.services.getFrontAllAnnouncements().subscribe((data: any) => {
            this.slider = data.filter(slider => slider.isSlider == true && slider.isDeleted == false && slider.isActive == true).sort((a, b) => {
                return a.order - b.order;
            });
            // console.log('slider',this.slider);
        });
    }

    gymHomeSlides: OwlOptions = {
        autoplayHoverPause: true,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        autoHeight: true,
        autoplay: true,
        dots: false,
        loop: true,
        nav: true,
        items: 1,
        navText: [
            "<i class='flaticon-arrows'></i>",
            "<i class='flaticon-right-arrow'></i>"
        ],
        autoplayTimeout: 4000
    }

}