<app-header-style-six></app-header-style-six>


<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <div class="page-title-content">
                <h2>Hizmetler</h2>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div style="margin-top: -74px;" class="row publish">
        <div class="instructor-area pt-100 pb-70">
            <div class="container">
                <div style="text-align: start;" class="section-title">
                    <div class="container">
                        <h3 class="services">HİZMETLER</h3>
                        <div class="row tab-pane">
                            <div class="col-lg-12 col-md-12 tab-pane">
                                <div class="faq-accordion faq-accordion-style-two">
                                    <div class="accordion-item" *ngFor="let item of service">
                                        <div class="accordion-header" (click)="toggleAccordionItem(item)">
                                            {{ item.title }}
                                            <i class='bx'
                                                [ngClass]="{'bx-chevron-down': !item.open, 'bx-chevron-up': item.open}"></i>
                                        </div>
                                        <div class="accordion-content" [hidden]="!item.open">
                                            {{ item.description }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h3 style="margin-top: 20px;">FAALİYETLER</h3>
                        <div class="row tab-pane">
                            <div class="col-lg-12 col-md-12 tab-pane">
                                <div class="faq-accordion faq-accordion-style-two">
                                    <div class="accordion-item" *ngFor="let item of activity">
                                        <div class="accordion-header" (click)="toggleAccordionItem(item)">
                                            {{ item.title }}
                                            <i class='bx'
                                                [ngClass]="{'bx-chevron-down': !item.open, 'bx-chevron-up': item.open}"></i>
                                        </div>
                                        <div class="accordion-content" [hidden]="!item.open">
                                            {{ item.description }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<app-header-style-two></app-header-style-two>
