<app-header-style-six></app-header-style-six>


<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <h2>Haberler</h2>
        </div>
    </div>
</div>

<div class="blog-details-area pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-image">
                        <img *ngIf="newsDetail?.frontAnnouncementRecords[0]?.fileUrl"
                            src="{{photoUrl+newsDetail?.frontAnnouncementRecords[0]?.fileUrl}}" alt="image">
                        <img *ngIf="!newsDetail?.frontAnnouncementRecords[0]?.fileUrl" src="/assets/italya/empty.png"
                            alt="image">
                    </div>
                    <div class="article-content">
                        <div class="entry-meta">
                            <ul>
                                <li>
                                    <i class='bx bx-calendar'></i>
                                    <span>Tarih</span>
                                    <a>{{newsDetail.modifiedDate | date:'dd/MM/yyyy'}}</a>
                                </li>
                            </ul>
                        </div>
                        <h3 [innerHTML]="newsDetail.frontAnnouncementRecords[0].shortDescription"></h3>
                        <p [innerHTML]="newsDetail.frontAnnouncementRecords[0].longDescription"></p>

                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <div class="widget widget_ednuv_posts_thumb">
                        <h3 class="widget-title">Son Haberler</h3>
                        <article *ngFor="let new1 of news?.slice(0,4)">
                            <a [routerLink]="['/haber-detay',new1?.code]" class="thumb">
                                <img *ngIf="new1?.frontAnnouncementRecords[0]?.fileUrl"
                                    src="{{photoUrl+new1?.frontAnnouncementRecords[0]?.fileUrl}}" alt="image">
                                <img *ngIf="!new1?.frontAnnouncementRecords[0]?.fileUrl" src="/assets/italya/empty.png"
                                    alt="image">
                                <span class="fullimage cover bg1" role="img"></span>
                            </a>
                            <div class="info">
                                <div><i class='bx bx-calendar'></i> {{ new1.modifiedDate | date:'dd/MM/yyyy' }}</div>
                                <h6 class="title usmall"><a
                                        [routerLink]="['/haber-detay',new1?.code]">{{new1?.frontAnnouncementRecords[0]?.title}}</a>
                                </h6>
                            </div>
                            <div class="clear"></div>
                        </article>
                    </div>
                </aside>
            </div>
        </div>
    </div>
</div>