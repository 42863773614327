<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="contact-footer-icon">
                    <h3>İletişim</h3>
                    <ul class="contact-us-link">
                        <li>
                            <div>
                                <i class='bx bx-map contact-icon'> <a target="_blank"
                                        href="https://www.google.com/maps/dir/39.9674521,32.7971643/161+Auburn+Rd,+Auburn+NSW+2144,+Avustralya/@0.2305044,6.2373852,3z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x6b12bcbbbf6f8f7b:0x862e3e886dc40f42!2m2!1d151.0311933!2d-33.8576305?entry=ttu">{{
                                        address }}</a></i>

                            </div>
                        </li>
                        <li *ngIf="telephone1!='-'">
                            <div>
                                <i class='bx bx-phone-call call contact-icon'> <a href="tel:{{ telephone1 }}">{{
                                        telephone1
                                        }}</a></i>

                            </div>
                        </li>
                        <!-- <li *ngIf="telephone2!='-'">
                            <div>
                                <i class='bx bx-phone-call contact-icon'><a href="tel:{{ telephone2 }}">{{ telephone2
                                        }}</a></i>

                            </div>
                        </li> -->
                        <li *ngIf="fax!='-'">
                            <div>
                                <i class='fa fa-fax contact-icon'><span>Fax:</span> <a href="tel:{{ fax }}"> {{ fax
                                        }}</a></i>


                            </div>
                        </li>
                    </ul>
                </div>
                <div class="single-footer-widget mb-30">

                    <ul class="social-link">
                        <li><a href="{{ facebook }}" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a>
                        </li>
                        <!-- <li><a href="{{ twitter }}" class="d-block" target="_blank"><i class='bi bi-twitter-x'></i></a>
                        </li> -->
                        <li><a href="{{ instagram }}" class="d-block" target="_blank"><i
                                    class='bx bxl-instagram'></i></a></li>
                        <!-- <li><a href="{{ linkedin }}" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a>
                        </li> -->
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Dini Konular</h3>
                    <ul class="support-link">
                        <li *ngFor="let item of submenus"><a [href]="item?.url"
                                target="_blank">{{item.frontMenuRecords[0]?.name}}</a></li>
                        <!-- <li><a href="https://kuran.diyanet.gov.tr/mushaf">Kur'an-ı Kerim</a></li>
                        <li><a href="https://www.diyanet.gov.tr/tr-TR/Kurumsal/Detay/10371">Hadis</a></li>
                        <li><a href="https://bagis.tdv.org/zekat/zekat-8">Zekat</a></li> -->

                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Vakıf</h3>
                    <ul class="support-link">
                        <li *ngFor="let item of orphanMenus?.slice(0,5)"><a
                                [href]="item?.frontMenuRecords[0]?.slug + '/'+item?.code ">{{item.frontMenuRecords[0]?.name}}</a>
                        </li>
                        <!-- <li><a routerLink="/din-gorevlilerimiz">Din Görevlilerimiz</a></li>
                        <li><a routerLink="/">Gençlik ve Spor Hizmetleri</a></li>
                        <li><a routerLink="/">Hayır Hizmetleri</a></li>
                        <li><a routerLink="/">Din Eğitimi ve Din Hizmetleri</a></li>
                        <li><a routerLink="/">Camiler</a></li> -->
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>&nbsp;</h3>
                    <ul class="support-link">
                        <li *ngFor="let item of orphanMenus?.slice(5)"><a
                                [href]="item?.frontMenuRecords[0]?.slug + '/'+item?.code ">{{item.frontMenuRecords[0]?.name}}</a>
                        </li>
                        <!-- <li><a routerLink="/din-gorevlilerimiz">Din Görevlilerimiz</a></li>
                        <li><a routerLink="/">Gençlik ve Spor Hizmetleri</a></li>
                        <li><a routerLink="/">Hayır Hizmetleri</a></li>
                        <li><a routerLink="/">Din Eğitimi ve Din Hizmetleri</a></li>
                        <li><a routerLink="/">Camiler</a></li> -->
                    </ul>
                </div>
            </div>
            <!-- <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Cenaze Fonu</h3>
                    <ul class="useful-link">
                        <li><a routerLink="/">2023 Yılı Cenaze Fonu Üye Bildirimi </a></li>
                        <li><a routerLink="/">ONLİNE ÜYELİK BAŞVURUSU</a></li>
                        <li><a routerLink="/">Bilgi Verme Yükümlülüğü</a></li>
                        <li><a routerLink="/">İsveç Dışında Vefat</a></li>
                        <li><a routerLink="/">Fon Üyesinin Vefatı Halinde Yapılacaklar</a></li>
                        <li><a routerLink="/">Aidat Yönetmeliği</a></li>
                        <li><a routerLink="/">Fondan Yararlanma Hakkı</a></li>
                        <li><a routerLink="/">Cenaze Fonunun Amacı</a></li> 
                    </ul>
                </div>
            </div> -->
        </div>
    </div>
    <div class="footer-bottom-area">
        <div class="container">
            <!-- <div class="logo">
                <a routerLink="/" class="d-inline-block"><img src="assets/img/logo.png" alt="image"></a>
            </div> -->
            <p class="devBy">© Designed and Developed by <a rel="noopener" href="https://vakifglobal.com/"
                    style="display:unset !important;" target="_blank"><img
                        src="../../../../assets/img/advPhotos/vakifGlobalLogo.png"
                        style="width:11%; margin-left:3px; " alt=""></a></p>
        </div>
    </div>
</footer>

<ngx-scrolltop></ngx-scrolltop>